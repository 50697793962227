import "./index.scss";
import "@popperjs/core";
import "bootstrap";
import "cookieconsent";
import type { Consent } from "cookieconsent";

declare global
{
    interface Window
    {
        privacyStatementUrl: string;
        cookieconsent: Consent;
    }
}

window.cookieconsent.initialise({
    "palette": {
        "popup": {
            "background": "#000"
        },
        "button": {
            "background": "#f1d600"
        }
    },
    "position": "bottom-right",
    "content": {
        "message": "Diese Website nutzt Cookies, um bestmögliche Funktionalität bieten zu können.",
        "dismiss": "Ok, verstanden",
        "link": "Mehr Infos",
        "href": window.privacyStatementUrl
    }
});
